import React from "react";
import theme from "theme";
import { Theme, Box, Em, Text, Hr, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				Visionary Spaces
			</title>
			<meta name={"description"} content={"Перетворіть свій простір, покращте своє життя"} />
			<meta property={"og:title"} content={"Visionary Spaces"} />
			<meta property={"og:description"} content={"Перетворіть свій простір, покращте своє життя"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6671692e8d4a0c00203097b0/images/1-1.jpg?v=2024-06-18T11:13:28.045Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6671692e8d4a0c00203097b0/images/1-1.jpg?v=2024-06-18T11:13:28.045Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6671692e8d4a0c00203097b0/images/1-1.jpg?v=2024-06-18T11:13:28.045Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6671692e8d4a0c00203097b0/images/1-1.jpg?v=2024-06-18T11:13:28.045Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6671692e8d4a0c00203097b0/images/1-1.jpg?v=2024-06-18T11:13:28.045Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6671692e8d4a0c00203097b0/images/1-1.jpg?v=2024-06-18T11:13:28.045Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6671692e8d4a0c00203097b0/images/1-1.jpg?v=2024-06-18T11:13:28.045Z"} />
		</Helmet>
		<Components.Header />
		<Section sm-padding="10px 0 30px 0" lg-padding="10px 0 60px 0" padding="50px 0 50px 0" quarkly-title="Statistics-12">
			<Override
				slot="SectionContent"
				max-width="1440px"
				align-items="flex-start"
				flex-direction="row"
				flex-wrap="wrap"
			/>
			<Box width="100%" sm-width="100%" />
			<Box
				width="100%"
				sm-width="100%"
				border-width="0px 0px 1px 0px"
				border-style="solid"
				border-color="--color-lightD2"
			>
				<Text margin="0px 0px 20px 0px" font="--base" color="--dark" sm-margin="0px 0px 20px 0px">
					<Em>
						Що ми пропонуємо
					</Em>
				</Text>
			</Box>
			<Box width="50%" sm-width="100%" margin="70px 0px 0px 0px" md-width="100%">
				<Text margin="0px 0px 20px 0px" font="--lead" color="--dark" sm-margin="0px 0px 20px 0px">
					Наші комплексні послуги з проектування та ремонту спрямовані на створення життєвого простору, який відповідає вашим особистим смакам і покращує ваш спосіб життя. Відчуйте індивідуальні рішення, які поєднують функціональність і елегантність під одним дахом.
				</Text>
			</Box>
			<Box width="50%" md-width="100%">
				<Box
					sm-margin="30px 0px 0px 0px"
					width="100%"
					sm-width="100%"
					margin="70px 0px 0px 0px"
					padding="0px 0px 0px 40px"
					sm-padding="0px 0px 0px 0px"
				>
					<Text
						color="--dark"
						sm-margin="0px 0px 20px 0px"
						letter-spacing="-.06em"
						lg-font="normal 400 54px/1.1em &quot;Inter&quot;, sans-serif"
						margin="0px 0px 54px 0px"
						font="--headline2"
					>
						Наші Послуги
					</Text>
					<Hr margin="0px 0px 0px 0px" />
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 0 0" background="#EDF2F6" sm-padding="60px 0 60px 0">
			<Box
				width="100%"
				display="flex"
				flex-direction="column"
				md-width="100%"
				md-align-items="center"
				md-justify-content="center"
				md-text-align="center"
				lg-width="100%"
				margin="0px 0px 56px 0px"
				align-items="center"
			>
				<Text
					margin="0px 0px 16px 0px"
					font="--headline2"
					color="--dark"
					md-text-align="center"
					sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
				>
					Повний спектр проектування та ремонту
				</Text>
			</Box>
			<Box display="grid" grid-template-columns="repeat(2, 1fr)" grid-gap="32px 4%" md-grid-template-columns="1fr">
				<Box
					padding="45px 45px"
					lg-padding="45px 30px"
					md-padding="45px 45px"
					background="#FFFFFF"
					border-radius="24px"
					justify-content="flex-start"
					flex-direction="column"
					display="flex"
				>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Індивідуальні планування
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Ми створюємо плани поверхів, які оптимізують простір, потік і освітлення, адаптовані до ваших повсякденних процедур і вподобань.
					</Text>
				</Box>
				<Box
					padding="45px 45px"
					lg-padding="45px 30px"
					md-padding="45px 45px"
					background="#FFFFFF"
					border-radius="24px"
					justify-content="flex-start"
					flex-direction="column"
					display="flex"
				>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Вишуканий стиль
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Незалежно від того, чи віддаєте ви перевагу сучасному мінімалізму чи класичній елегантності, наші проекти покращать естетику вашого дому.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 0 0" background="#EDF2F6" sm-padding="60px 0 60px 0">
			<Box
				width="100%"
				display="flex"
				flex-direction="column"
				md-width="100%"
				md-align-items="center"
				md-justify-content="center"
				md-text-align="center"
				lg-width="100%"
				margin="0px 0px 56px 0px"
				align-items="center"
			>
				<Text
					margin="0px 0px 16px 0px"
					font="--headline2"
					color="--dark"
					md-text-align="center"
					sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
				>
					Трансформація кухні та ванної кімнати
				</Text>
			</Box>
			<Box display="grid" grid-template-columns="repeat(2, 1fr)" grid-gap="32px 4%" md-grid-template-columns="1fr">
				<Box
					padding="45px 45px"
					lg-padding="45px 30px"
					md-padding="45px 45px"
					background="#FFFFFF"
					border-radius="24px"
					justify-content="flex-start"
					flex-direction="column"
					display="flex"
				>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Елітний ремонт
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Ми оновлюємо ці важливі приміщення за допомогою високоякісного обладнання та обробки, поєднуючи функціональність із розкішшю.
					</Text>
				</Box>
				<Box
					padding="45px 45px"
					lg-padding="45px 30px"
					md-padding="45px 45px"
					background="#FFFFFF"
					border-radius="24px"
					justify-content="flex-start"
					flex-direction="column"
					display="flex"
				>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Розумні інтеграції
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Перейдіть до найновішої технології розумного будинку для підвищення зручності та енергоефективності.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 0 0" background="#EDF2F6" sm-padding="60px 0 60px 0">
			<Box
				width="100%"
				display="flex"
				flex-direction="column"
				md-width="100%"
				md-align-items="center"
				md-justify-content="center"
				md-text-align="center"
				lg-width="100%"
				margin="0px 0px 56px 0px"
				align-items="center"
			>
				<Text
					margin="0px 0px 16px 0px"
					font="--headline2"
					color="--dark"
					md-text-align="center"
					sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
				>
					Індивідуальний вибір матеріалу
				</Text>
			</Box>
			<Box display="grid" grid-template-columns="repeat(2, 1fr)" grid-gap="32px 4%" md-grid-template-columns="1fr">
				<Box
					padding="45px 45px"
					lg-padding="45px 30px"
					md-padding="45px 45px"
					background="#FFFFFF"
					border-radius="24px"
					justify-content="flex-start"
					flex-direction="column"
					display="flex"
				>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Якісні матеріали
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Вибирайте з нашої колекції високоякісної деревини, каменю та металу для довговічності та стилю.
					</Text>
				</Box>
				<Box
					padding="45px 45px"
					lg-padding="45px 30px"
					md-padding="45px 45px"
					background="#FFFFFF"
					border-radius="24px"
					justify-content="flex-start"
					flex-direction="column"
					display="flex"
				>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Екологічні варіанти
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Наші екологічно чисті матеріали забезпечують менший вплив на навколишнє середовище без шкоди для якості.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 0 0" background="#EDF2F6" sm-padding="60px 0 60px 0">
			<Box
				width="100%"
				display="flex"
				flex-direction="column"
				md-width="100%"
				md-align-items="center"
				md-justify-content="center"
				md-text-align="center"
				lg-width="100%"
				margin="0px 0px 56px 0px"
				align-items="center"
			>
				<Text
					margin="0px 0px 16px 0px"
					font="--headline2"
					color="--dark"
					md-text-align="center"
					sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
				>
					Освітлення та акценти
				</Text>
			</Box>
			<Box display="grid" grid-template-columns="repeat(2, 1fr)" grid-gap="32px 4%" md-grid-template-columns="1fr">
				<Box
					padding="45px 45px"
					lg-padding="45px 30px"
					md-padding="45px 45px"
					background="#FFFFFF"
					border-radius="24px"
					justify-content="flex-start"
					flex-direction="column"
					display="flex"
				>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Спеціальні рішення для освітлення
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Освітіть свій дім за допомогою схем освітлення, які адаптуються до вашого настрою та покращують атмосферу.
					</Text>
				</Box>
				<Box
					padding="45px 45px"
					lg-padding="45px 30px"
					md-padding="45px 45px"
					background="#FFFFFF"
					border-radius="24px"
					justify-content="flex-start"
					flex-direction="column"
					display="flex"
				>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Художні акценти
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Виберіть із створених на замовлення художніх інсталяцій і дизайнерських світильників, які відображають ваш особистий стиль.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 0 0" background="#EDF2F6" sm-padding="60px 0 60px 0">
			<Box
				width="100%"
				display="flex"
				flex-direction="column"
				md-width="100%"
				md-align-items="center"
				md-justify-content="center"
				md-text-align="center"
				lg-width="100%"
				margin="0px 0px 56px 0px"
				align-items="center"
			>
				<Text
					margin="0px 0px 16px 0px"
					font="--headline3"
					color="--dark"
					md-text-align="center"
					sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
				>
					Наші послуги виходять за рамки простої естетики, зосереджуючись на створенні просторів, які покращують якість вашого життя. Будь-який ремонт починається з вашого бачення і закінчується захоплюючою реальністю. Це лише короткий огляд того, як ми можемо змінити ваш простір.
				</Text>
			</Box>
		</Section>
		<Components.Contacts>
			<Override slot="text" />
		</Components.Contacts>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"661cef388e2e8e00217acf89"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});